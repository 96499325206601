import React, { FC } from "react";
import { Stack, Button, CardActions, Skeleton } from "@mui/material";
import styled from "@emotion/styled";
import classNames from "classnames";
import {
  Maybe,
  Post,
  UpvoteDownvotePostFragmentDoc,
  useGetFeatureToPlatformPermissionQuery,
  PlatformPermissionFromFeatureTypes,
  ClubPermissionsBasedOnMemberType,
  useMeQuery,
  useToggleUpvotePostMutation,
  useToggleDownvotePostMutation,
} from "api";
import { useNavigate } from "react-router-dom";
import { useSharePost } from "components/SharePost";
import { isEmpty } from "lodash-es";
import { COLORS } from "assets/colors";
import { useUpgradePlanContext } from "context/UpgradePlan/UpgradePlanContext";
import { usePermissionFeatureMapping } from "hooks";
import { useClubPermission } from "hooks";
import { UpgradeModalInitialised, sendEvent } from "ga4";

interface Props {
  post: Maybe<Post> | undefined;
  isCommentExists?: boolean;
  loading?: boolean;
}

export const PostBottomActions: FC<Props> = ({
  post,
  isCommentExists = false,
  loading,
}) => {
  const { platformFeatureMap, clubFeatureMap } = usePermissionFeatureMapping();
  const navigate = useNavigate();
  const { handleModalOpen } = useUpgradePlanContext();
  const { handleOpenModal: sharePostModal } = useSharePost();
  const [toggleUpvote, { loading: toggleUpvoteLoading }] =
    useToggleUpvotePostMutation();
  const [toggleDownvote, { loading: toggleDownvoteLoading }] =
    useToggleDownvotePostMutation();
  const { data } = useMeQuery();
  const { permissions, token } = useClubPermission(post?.Club?.clubId);
  const { data: platformPermission } = useGetFeatureToPlatformPermissionQuery();

  const clubEngage = permissions?.includes(
    ClubPermissionsBasedOnMemberType.CanEngage
  );

  const handleUpvote: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    sendEvent({
      event: "user_engaged",
      icon_name: "upvote",
    });
    if (post?.Club) {
      if (!clubEngage) {
        sendEvent({
          event: "upgrade_modal_initialised",
          component_name: "post",
          feature_name_list: clubFeatureMap[post?.Club?.clubId as string],
        });
        handleModalOpen();
        return;
      }
    } else if (
      !platformPermission?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanEngage
      )
    ) {
      sendEvent({
        event: "upgrade_modal_initialised",
        component_name: "post",
        feature_name_list:
          platformFeatureMap[PlatformPermissionFromFeatureTypes.CanEngage],
      });
      handleModalOpen();
      return;
    }
    toggleUpvote({
      variables: { postId: post?.postId, clubId: post?.Club?.clubId },
    });
  };

  const handleDownvote: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    sendEvent({
      event: "user_engaged",
      icon_name: "downvote",
    });
    if (post?.Club) {
      if (!clubEngage) {
        sendEvent({
          event: "upgrade_modal_initialised",
          component_name: "post",
          feature_name_list: clubFeatureMap[post?.Club?.clubId as string],
        });
        handleModalOpen();
        return;
      }
    } else if (
      !platformPermission?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanEngage
      )
    ) {
      sendEvent({
        event: "upgrade_modal_initialised",
        component_name: "post",
        feature_name_list:
          platformFeatureMap[PlatformPermissionFromFeatureTypes.CanEngage],
      });
      handleModalOpen();
      return;
    }
    toggleDownvote({
      variables: { postId: post?.postId, clubId: post?.Club?.clubId },
    });
  };

  const handleComment: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    sendEvent({
      event: "user_engaged",
      icon_name: "comment",
    });
    if (post?.Club) {
      if (!clubEngage) {
        sendEvent({
          event: "upgrade_modal_initialised",
          component_name: "post",
          feature_name_list: clubFeatureMap[post?.Club?.clubId as string],
        });
        handleModalOpen();
        return;
      }
    } else if (
      !platformPermission?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanEngage
      )
    ) {
      sendEvent({
        event: "upgrade_modal_initialised",
        component_name: "post",
        feature_name_list:
          platformFeatureMap[PlatformPermissionFromFeatureTypes.CanEngage],
      });
      handleModalOpen();
      return;
    }

    navigate(`/posts/${post?.postId}`);
  };

  const handleShare: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    sendEvent({
      event: "user_engaged",
      icon_name: "share",
    });

    if (post?.Club) {
      if (!clubEngage) {
        sendEvent({
          event: "upgrade_modal_initialised",
          component_name: "post",
          feature_name_list: clubFeatureMap[post?.Club?.clubId as string],
        });
        handleModalOpen();
        return;
      }
    } else if (
      !platformPermission?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanEngage
      )
    ) {
      sendEvent({
        event: "upgrade_modal_initialised",
        component_name: "post",
        feature_name_list:
          platformFeatureMap[PlatformPermissionFromFeatureTypes.CanEngage],
      });
      handleModalOpen();
      return;
    }

    if (post?.sharePostId) {
      sharePostModal(post.getSharedPost);
    } else {
      sharePostModal(post);
    }
  };

  let isShareButtonShown: boolean;
  if (!isEmpty(post?.sharePostId) && Boolean(post?.getSharedPost?.Club)) {
    if (
      data?.me?.isVerified &&
      post?.getSharedPost?.clubPostShareSetting?.isVerifiedCanShare
    ) {
      isShareButtonShown = true;
    } else if (
      data?.me?.greenTick &&
      post?.getSharedPost?.clubPostShareSetting?.greenTickCanShare
    ) {
      isShareButtonShown = true;
    } else if (post?.getSharedPost?.clubPostShareSetting?.normalUserCanShare) {
      isShareButtonShown = true;
    } else {
      isShareButtonShown = false;
    }
  } else if (Boolean(post?.Club)) {
    if (
      data?.me?.isVerified &&
      post?.clubPostShareSetting?.isVerifiedCanShare
    ) {
      isShareButtonShown = true;
    } else if (
      data?.me?.greenTick &&
      post?.clubPostShareSetting?.greenTickCanShare
    ) {
      isShareButtonShown = true;
    } else if (post?.clubPostShareSetting?.normalUserCanShare) {
      isShareButtonShown = true;
    } else {
      isShareButtonShown = false;
    }
  } else {
    isShareButtonShown = true;
  }

  const isShownPostBottom = Boolean(post?.Club) ? clubEngage : true;
  // const loading = true;
  return (
    <CustomCardActions>
      {isShownPostBottom &&
        (loading ? (
          <CustomStack
            gap={2}
            direction="row"
            isCommentExists={isCommentExists}
          >
            {Array.from(new Array(4))?.map((btn, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                sx={{ width: "25%", borderRadius: "5px", height: "25px" }}
              />
            ))}
          </CustomStack>
        ) : (
          <CustomStack direction="row" isCommentExists={isCommentExists}>
            <Button
              aria-label="Upvote"
              id={`btnUpvote:${post?.postId}`}
              color="inherit"
              onClick={handleUpvote}
              className={classNames({ active: post?.isUpvoted })}
              disabled={
                (post?.Club ? !clubEngage : false) || toggleUpvoteLoading
              }
            >
              <UpvoteIcon />
              {Boolean(post?.upvotecount) && <Count>{post?.upvotecount}</Count>}
            </Button>
            <Button
              aria-label="Downvote"
              id={`btnDownvote:${post?.postId}`}
              color="inherit"
              onClick={handleDownvote}
              className={classNames({ active: post?.isDownvoted })}
              disabled={
                (post?.Club ? !clubEngage : false) || toggleDownvoteLoading
              }
            >
              <DownvoteIcon />
              {Boolean(post?.downvotecount) && (
                <Count>{post?.downvotecount}</Count>
              )}
            </Button>
            <Button
              aria-label="Comment"
              id={`btnComment:${post?.postId}`}
              color="inherit"
              onClick={handleComment}
              disabled={post?.Club ? !clubEngage : false}
            >
              <CommentIcon />
              {Boolean(post?.commentcount) && (
                <Count>{post?.commentcount}</Count>
              )}
            </Button>
            {isShareButtonShown && (
              <Button
                aria-label="Share"
                id={`btnShare:${post?.postId}`}
                color="inherit"
                onClick={handleShare}
                disabled={post?.Club ? !clubEngage : false}
              >
                <ShareIcon />
                {Boolean(post?.sharecount) && <Count>{post?.sharecount}</Count>}
              </Button>
            )}
          </CustomStack>
        ))}
    </CustomCardActions>
  );
};

const Count = styled("span")({
  fontWeight: "bold",
  marginLeft: "4px",
});

const UpvoteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
  </svg>
);

const DownvoteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17" />
  </svg>
);

const CommentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
  </svg>
);

const ShareIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="18" cy="5" r="3"></circle>
    <circle cx="6" cy="12" r="3"></circle>
    <circle cx="18" cy="19" r="3"></circle>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
  </svg>
);

const CustomCardActions = styled(CardActions)`
  padding-bottom: 4px;
  .MuiTypography-subtitle2 {
    margin-left: 8px;
    line-height: 1.5rem;
  }
`;

const CustomStack = styled(Stack)<{ isCommentExists: boolean }>`
  border-top: 1px solid ${({ theme }: any) => theme?.palette?.divider};
  flex: 1;
  padding: 4px 8px;
  ${({ theme, isCommentExists }: any) =>
    isCommentExists &&
    `
        border-bottom: 1px solid ${theme?.palette?.divider};
        padding-bottom: 4px;
    `}

  .MuiButton-root {
    &.active {
      color: ${COLORS.buttonActive};
    }
  }

  .MuiTypography-caption {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 6px;
    color: inherit;
  }
`;
