import React, { ChangeEvent, FC, ReactNode } from "react";
import {
  Divider,
  Stack,
  Select,
  SelectChangeEvent,
  Typography,
  Skeleton,
} from "@mui/material";
import {
  GetUserFeedTypeListDocument,
  useSetDefaultFeedMutation,
  GetUserFeedTypeListQuery,
} from "api";
import { sendEvent } from "ga4";
import { loadOptions } from "@babel/core";

interface FeedSelectionProps {
  data: GetUserFeedTypeListQuery | undefined;
  feedTypeId?: string | null;
  loading?: boolean;
}

const FeedSelection: FC<FeedSelectionProps> = ({
  data,
  feedTypeId,
  loading,
}) => {
  const [setDefaultFeed] = useSetDefaultFeedMutation();

  const handleChange = (event: SelectChangeEvent<string | null>) => {
    const id = event.target.value;
    const newEvent = event as unknown as ChangeEvent<HTMLSelectElement>;
    const selectedOption =
      newEvent.target.options[newEvent.target.selectedIndex];
    const feed_name = selectedOption.getAttribute("data-feed-name") || "";

    sendEvent({
      event: "set_default_feed",
      feed_name,
    });
    setDefaultFeed({
      variables: { feedTypeId: id },
      update: (cache) => {
        cache.updateQuery({ query: GetUserFeedTypeListDocument }, (data) => ({
          getUserFeedTypeList: data.getUserFeedTypeList.map((item) => {
            if (id != item.feedTypeId) {
              return {
                ...item,
                default: false,
              };
            }
            return item;
          }),
        }));
      },
    });
  };

  //   const l = true;
  return (
    <Stack
      direction="row"
      alignItems="center"
      paddingTop={1}
      spacing={0.25}
      sx={{
        paddingLeft: 0.75,
        paddingRight: 0.75,
        paddingBottom: 0.75,
        "@media (min-width:540px)": {
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
        },
      }}
    >
      <Divider sx={{ flex: 1 }} />
      <Typography fontSize={".688rem"} padding={"0px 4px"}>
        Show{" "}
      </Typography>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={"15%"}
          sx={{ borderRadius: "5px" }}
        />
      ) : (
        <Select
          native
          value={feedTypeId}
          onChange={handleChange}
          size="small"
          data-tut="reactour__select_feed"
          sx={{
            width: "95px",
            fontSize: "0.688rem",
            ".MuiNativeSelect-select": {
              padding: "4px 8px !important",
            },
            svg: {
              right: "0px",
            },
          }}
        >
          {data?.getUserFeedTypeList?.map((item) => (
            <option
              key={item?.feedTypeId}
              value={item?.feedTypeId as string}
              data-feed-name={item?.feedDisplayName}
            >
              {item?.feedDisplayName}
            </option>
          ))}
        </Select>
      )}
    </Stack>
  );
};

export default FeedSelection;
