/* eslint-disable react/display-name */
import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useRef,
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useMemo,
} from "react";
import { PostInput } from "./PostInput";
import { ImageEditor } from "./ImageEditor";
import { useCallback } from "react";
import { size, trim } from "lodash-es";
import imageCompression from "utills/image-compression";
import {
  Maybe,
  MediaInput,
  Post,
  Media,
  useUpdatePostMutation,
  useMeQuery,
} from "api";
import { ModalHeader } from "./ModalHeader";
import { UserDisplay } from "./UserDisplay";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { ImageSmallIcon, AttachmentIcon, AddVideoIcon, AudioIcon } from "icons";
import { Image } from "./Image";
import { manageAnyHashtag } from "utills";
import { DisplayPoll } from "./DisplayPoll";
import { Video } from "./Video";
import { Pdf } from "./Pdf";
import { isEmpty } from "lodash-es";
import { toast } from "react-hot-toast";
import DisplayAudioPlayer from "./DisplayAudioPlayer";
import { useConfirm } from "material-ui-confirm";
import { AlertPaperconfigOption } from "helper";
import { COLORS } from "assets/colors";
import SharedPostComponent from "components/PostComponent/SharedPostComponent";
import axios from "axios";
import { EDIT_LARGE_FILE_THUMBNAIL, MEDIA_UPLOAD_URL } from "config";
import { useAuth } from "react-oidc-context";
import { useClubPermission } from "hooks";
import useUploadLargeFile from "hooks/uploadLargeFiles";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const MAX_IMAGES = 3;

interface VideoThumbnailArgs {
  name: string;
  type: string;
  uri: string;
}
const videoThumbnailArgs: VideoThumbnailArgs[] = [];

interface PostCreatorProps {
  post: Maybe<Post> | undefined;
  handleCloseModal: () => void;
}

const getPage = (pathname: string) => {
  return pathname;
};

export const PostCreator = forwardRef<any, PostCreatorProps>(
  ({ post, handleCloseModal }, ref) => {
    const confirm = useConfirm();
    const { data: meData } = useMeQuery();
    const [fileSize, fileSizeMessage] = useMemo(() => {
      let fileSize = 10 * 1024 * 1024;
      let fileSizeMessage = "Video size exceeds 10 MB";
      if (meData?.me?.canUploadLargeFiles) {
        fileSize = 100 * 1024 * 1024;
        fileSizeMessage = "Video size exceeds 100 MB";
      }
      return [fileSize, fileSizeMessage];
    }, [meData?.me]);
    const auth = useAuth();
    const uploadLargeFile = useUploadLargeFile();
    const initImages = () => {
      if (!post?.media) return [];
      return post?.media?.filter((m) => m?.type.includes("image/"));
    };
    const initVideo = () => {
      if (!post?.media) return undefined;
      return post?.media?.find((m) => m?.type.includes("video/"));
    };
    const initAudio = () => {
      if (!post?.media) return undefined;
      return post?.media?.find((m) => m?.type.includes("audio/"));
    };
    const initDoc = () => {
      if (!post?.media) return undefined;
      return post?.media?.find((m) => m?.type.includes("application/"));
    };
    const imageRef = useRef<HTMLInputElement>(null);
    const videoRef = useRef<HTMLInputElement>(null);
    const audioRef = useRef<HTMLInputElement>(null);
    const docRef = useRef<HTMLInputElement>(null);
    const videoThumbnailRef = useRef<HTMLInputElement>(null);
    const [modal, setModal] = useState<
      "CREATE_A_POLL" | "EDIT_IMAGE" | "TEXT_POST"
    >("TEXT_POST");
    const [text, setText] = useState<string>(post?.textV2 || "");
    const [images, setImages] = useState<Array<File | Maybe<Media>>>(
      initImages || []
    );
    const [editImgaeIndex, setEditImageIndex] = useState<number | undefined>(
      undefined
    );
    const [video, setVideo] = useState<File | Maybe<Media> | undefined>(
      initVideo
    );
    const [audio, setAudio] = useState<File | Maybe<Media> | undefined>(
      initAudio
    );
    const [doc, setDoc] = useState<File | Maybe<Media> | undefined>(initDoc);
    const [loading, setLoading] = useState<boolean>(false);

    const [updatePost] = useUpdatePostMutation();
    const { token } = useClubPermission(post?.Club?.clubId);
    const [videoThumbnail, setVideoThumbnail] = useState<Array<File> | any>([]);
    console.log("THUMBNAIL", videoThumbnail[0]);
    const uploadVideoThumbnail = (files: File, largeFileReturnData?: any) => {
      const formData = new FormData();
      formData.append("file", files);
      formData.append("videoFilenameData", largeFileReturnData);
      return axios.post(EDIT_LARGE_FILE_THUMBNAIL, formData, {
        headers: {
          "Content-Type": "multipart/form-date",
          Authorization: `Bearer ${auth?.user?.access_token}`,
        },
      });
    };

    useEffect(() => {
      async function pasteHandler(this: Document, e: any) {
        if (e.clipboardData && e.clipboardData.items.length > 0) {
          for (let i = 0; i < e.clipboardData.items.length; i++) {
            if (new RegExp("image/").test(e.clipboardData.items[i].type)) {
              if (images.length + 1 > MAX_IMAGES) {
                return confirm({
                  ...AlertPaperconfigOption,
                  title: "Upload Error!",
                  description: "You can upload atmost 3 images",
                });
              } else {
                const blob = e.clipboardData.items[i].getAsFile();
                if (blob) {
                  if (blob.size > 10 * 1024 * 1024) {
                    return confirm({
                      ...AlertPaperconfigOption,
                      title: "Upload Error!",
                      description: "Can not upload image size more than 10 MB",
                    });
                  }
                  setImages((x) => [...x, blob]);
                }
              }
            }
          }
        }
      }
      window.addEventListener("paste", pasteHandler);
      return () => {
        window.removeEventListener("paste", pasteHandler);
      };
    }, [window, images, setImages]);

    const handleClose = () => {
      if (
        !(isEmpty(text) && isEmpty(images) && isEmpty(video) && isEmpty(doc))
      ) {
        confirm({
          ...AlertPaperconfigOption,
          title: "Are you sure?",
          description: "You won't be able to revert this!",
          confirmationText: "Discard",
          cancellationText: "Go Back",
          cancellationButtonProps: {
            variant: "contained",
            // color: "primary",
            style: {
              display: "flex",
              backgroundColor: COLORS.buttonActive,
            },
          },
          confirmationButtonProps: {
            variant: "contained",
            // color: "primary",
            style: {
              backgroundColor: "#d33",
            },
          },
        }).then(() => {
          handleCloseModal();
        });
      } else {
        handleCloseModal();
      }
    };

    useImperativeHandle(ref, () => ({ handleClose }));

    const handleImageLoad = () => imageRef?.current?.click();
    const handleVideoLoad = () => videoRef?.current?.click();
    const handleAudioLoad = () => audioRef?.current?.click();
    const handlePdfLoad = () => docRef?.current?.click();
    const handleVideoThumbnailLoad = () => videoThumbnailRef?.current?.click();

    const handleImagesUpload = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        if (!files?.length) return;
        if (Array.from(files).some((x) => x.size > 10 * 1024 * 1024)) {
          event.target.value = "";
          return confirm({
            ...AlertPaperconfigOption,
            title: "Upload Error!",
            description: "Can not upload image size more than 10 MB",
          });
        }
        if (size(images) + Number(files?.length) > MAX_IMAGES) {
          event.target.value = "";
          return confirm({
            ...AlertPaperconfigOption,
            title: "Upload Error!",
            description: "You can upload atmost 3 images",
          });
        }
        setImages((images) => [...images, ...Array.from(files)]);
        event.target.value = "";
      },
      [images]
    );

    const handleVideoUpload = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        if (!files?.length) return;
        const video = files[0];
        if (video.size > fileSize) {
          return confirm({
            ...AlertPaperconfigOption,
            title: "Upload Error!",
            description: fileSizeMessage,
          });
        }
        setVideo(files[0]);
        event.target.value = "";
      },
      [setVideo, fileSize, fileSizeMessage]
    );

    // HANDLE VIDEO THUMBNAIL UPLOAD
    const handleVideoThumbnailUpload = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const files = event.target.files;
      setVideoThumbnail(files as any);
    };
    const handleVideoThumbnailRemove = async () => {
      setVideoThumbnail([]);
    };

    const handleAudioUpload = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        if (!files?.length) return;
        const audio = files[0];
        const ext = audio.name.split(".").pop();
        if (!["mp3", "wav", "m4a"].includes(ext!)) {
          return confirm({
            ...AlertPaperconfigOption,
            title: "Upload Error!",
            description: "Upload file should be .mp3, .wav, .m4a",
          });
        }
        if (audio.size > 10 * 1024 * 1024) {
          return confirm({
            ...AlertPaperconfigOption,
            title: "Upload Error!",
            description: "Audio size exceeds 10MB",
          });
        }
        setAudio(files[0]);
        event.target.value = "";
      },
      [setAudio]
    );

    const handlePDFUpload = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        if (!files?.length) return;
        const pdf = files[0];
        if (pdf.size > 15 * 1024 * 1024) {
          return confirm({
            ...AlertPaperconfigOption,
            title: "Upload Error!",
            description: "Pdf size exceeds 15MB",
          });
        }
        setDoc(files[0]);
        event.target.value = "";
      },
      [setDoc]
    );

    const uploadFiles = (files: Array<File>) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      return axios.post(MEDIA_UPLOAD_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-date",
          Authorization: `Bearer ${auth?.user?.access_token}`,
        },
      });
    };

    const handleCreatePost = async (event: SyntheticEvent) => {
      event.preventDefault();
      let videoFilenameData = post?.media?.at(0)?.name;
      try {
        setLoading(true);
        const willUploadImage: Array<File> = [];
        const media: Array<Maybe<MediaInput>> = [];
        images.map((i) => {
          if (i instanceof File || i instanceof Blob)
            willUploadImage.push(i as File);
          else {
            media.push({
              name: i?.name as string,
              type: i?.type as string,
              uri: i?.name as string,
            });
          }
        });

        const files: Array<File> = [];
        if (willUploadImage.length) {
          const compressImages = await imageCompression<Array<File>>(
            willUploadImage
          );
          if (compressImages) {
            files.push(...compressImages);
          }
        }
        if (video instanceof File || video instanceof Blob) {
          if (meData?.me?.canUploadLargeFiles) {
            const largeFileReturnData = await uploadLargeFile(video as File);
            if (largeFileReturnData) {
              media.push(largeFileReturnData);
              videoThumbnailArgs?.push(largeFileReturnData);
            }

            console.log("LARGE FILE", largeFileReturnData);

            videoFilenameData = largeFileReturnData?.uri;
          } else {
            files.push(video as File);
          }
        } else if (video) {
          media.push({
            name: video?.name as string,
            type: video?.type as string,
            uri: video?.name as string,
          });
        }
        if (audio instanceof File || audio instanceof Blob) {
          files.push(audio as File);
        } else if (audio) {
          media.push({
            name: audio?.name as string,
            type: audio?.type as string,
            uri: audio?.name as string,
          });
        }
        if (doc instanceof File || doc instanceof Blob) {
          files.push(doc as File);
        } else if (doc) {
          media.push({
            name: doc?.name as string,
            type: doc?.type as string,
            uri: doc?.name as string,
          });
        }

        if (files.length) {
          const { data } = await uploadFiles(files);
          data?.forEach((item: any) => {
            const file = JSON.parse(item.uploadfilename);
            media.push({
              name: item.uploadfilename,
              type: file.MIME.replace(/\/.*/i, "/*"),
              uri: item.uploadfilename,
            });
          });
        }

        if (videoThumbnail && !isEmpty(videoThumbnail)) {
          const compressedImage = await imageCompression<File>(
            videoThumbnail[0]
          );

          const { data: EditThumbailReturnData } = await uploadVideoThumbnail(
            videoThumbnail[0],
            videoFilenameData
          );

          media?.pop();
          media.push({
            name: EditThumbailReturnData.uploadfilename,
            type: JSON?.parse(
              EditThumbailReturnData.uploadfilename
            ).MIME.replace(/\/.*/i, "/*"),
            uri: EditThumbailReturnData.uploadfilename,
          });
        }
        updatePost({
          variables: {
            data: {
              postId: post?.postId,
              postData: {
                text: manageAnyHashtag(trim(text)),
                media,
                clubId: post?.Club?.clubId,
                clubBasedPermissionToken: token,
              },
            },
          },
        });
        handleCloseModal();
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    const isDisable =
      isEmpty(text) &&
      isEmpty(images) &&
      !video &&
      !doc &&
      !audio &&
      !post?.hasPoll;
    const isImageDisable = Boolean(images.length >= 3 || video || doc || audio);
    const isMediaDisable = Boolean(images.length > 0 || video || doc || audio);

    console.log("THUMB", videoThumbnail[0]);

    return (
      <React.Fragment>
        {loading && (
          <Loader>
            <CircularProgress />
          </Loader>
        )}

        {modal === "EDIT_IMAGE" && (
          <ImageEditor
            image={
              editImgaeIndex !== undefined ? images[editImgaeIndex] : undefined
            }
            editImgaeIndex={editImgaeIndex}
            setImages={setImages}
            setModal={setModal}
            setEditImageIndex={setEditImageIndex}
          />
        )}
        {modal === "TEXT_POST" && (
          <PostInput
            header={<ModalHeader title="Edit Post" onClick={handleClose} />}
            postHeader={<UserDisplay club={post?.Club} />}
            text={text}
            setText={setText}
            setModal={setModal}
            renderImages={images.map((image, key) => (
              <Image
                key={key}
                image={image}
                onClickDelete={() =>
                  setImages((x) => x.filter((_, i) => i !== key))
                }
                onClickEdit={() => {
                  setEditImageIndex(key);
                  setModal("EDIT_IMAGE");
                }}
              />
            ))}
            renderPoll={
              post?.getPoll ? <DisplayPoll poll={post?.getPoll} /> : null
            }
            renderSherePost={
              post?.sharePostId ? (
                <SharedPostComponent post={post?.getSharedPost} />
              ) : null
            }
            renderVideo={
              video ? (
                <Video
                  video={video}
                  onClick={() => {
                    setVideo(undefined);
                    setVideoThumbnail([]);
                  }}
                  videoThumbnail={videoThumbnail[0]}
                >
                  {meData?.me?.canUploadLargeFiles && (
                    <Box
                      gap={2}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        position: "absolute",

                        justifyContent: "center",

                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Box>
                        <Tooltip title="Upload thumbnail">
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={handleVideoThumbnailLoad}
                            sx={{
                              color: "white",
                              backgroundColor: "rgba(27, 106, 201, 0.8)",
                              zIndex: 1,

                              filter: "blur",
                              border: "white!important",
                            }}
                          >
                            <Typography fontSize="0.8rem" color="white">
                              {!isEmpty(videoThumbnail) ||
                              (video as any)?.uri?.ThumbnailUrl
                                ? "Change Thumbnail"
                                : "Add Thumbnail"}
                            </Typography>
                            {/* <FileUploadOutlinedIcon /> */}
                          </Button>
                        </Tooltip>
                      </Box>
                      {!isEmpty(videoThumbnail) && (
                        <Box>
                          <Tooltip title="Remove thumbnail">
                            <Button
                              variant="outlined"
                              onClick={handleVideoThumbnailRemove}
                              sx={{
                                backgroundColor: "rgba(27, 106, 201, 0.8)",
                                zIndex: 1,

                                filter: "blur",
                                border: "white!important",
                              }}
                              size="small"
                            >
                              <Typography fontSize="0.8rem" color="white">
                                Remove
                              </Typography>
                              {/* <ClearOutlinedIcon /> */}
                            </Button>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  )}
                </Video>
              ) : null
            }
            renderAudio={
              audio ? (
                <DisplayAudioPlayer
                  audio={audio}
                  onClick={() => setAudio(undefined)}
                />
              ) : null
            }
            renderDoc={doc ? <Pdf onClick={() => setDoc(undefined)} /> : null}
            uploadImageButton={
              <StyledTooltip
                title="Upload an image"
                placement="top-start"
                arrow
              >
                <IconButton
                  disabled={isImageDisable}
                  sx={{ color: COLORS.buttonActive }}
                  onClick={handleImageLoad}
                >
                  <ImageSmallIcon />
                </IconButton>
              </StyledTooltip>
            }
            uploadVideoButton={
              <StyledTooltip title="Upload a video" placement="top" arrow>
                <IconButton
                  disabled={isMediaDisable}
                  sx={{ color: COLORS.buttonActive }}
                  onClick={handleVideoLoad}
                >
                  <AddVideoIcon />
                </IconButton>
              </StyledTooltip>
            }
            uploadAudioButton={
              <StyledTooltip title="Upload Audio" placement="top" arrow>
                <IconButton
                  disabled={isMediaDisable}
                  sx={{ color: COLORS.buttonActive }}
                  onClick={handleAudioLoad}
                >
                  <AudioIcon />
                </IconButton>
              </StyledTooltip>
            }
            uploadPdfButton={
              <StyledTooltip title="Upload a pdf" placement="top" arrow>
                <IconButton
                  disabled={isMediaDisable}
                  sx={{ color: COLORS.buttonActive }}
                  onClick={handlePdfLoad}
                >
                  <AttachmentIcon />
                </IconButton>
              </StyledTooltip>
            }
            createPostButton={
              <CustomButton
                sx={{
                  backgroundColor: COLORS.buttonActive,
                  color: COLORS.buttonTextActive,
                }}
                variant="contained"
                disabled={isDisable}
                onClick={handleCreatePost}
                style={{ maxWidth: 120, width: "100%" }}
              >
                Save
              </CustomButton>
            }
          />
        )}
        <HiddenInput
          type="file"
          accept="image/*"
          ref={imageRef}
          onChange={handleImagesUpload}
          multiple
        />
        <HiddenInput
          type="file"
          accept="image/*"
          ref={videoThumbnailRef}
          onChange={handleVideoThumbnailUpload}
        />
        <HiddenInput
          type="file"
          accept="video/*"
          ref={videoRef}
          onChange={handleVideoUpload}
        />
        <HiddenInput
          type="file"
          accept=".pdf"
          ref={docRef}
          onChange={handlePDFUpload}
        />
        <HiddenInput
          type="file"
          accept=".mp3,.wav,.m4a"
          ref={audioRef}
          onChange={handleAudioUpload}
        />
      </React.Fragment>
    );
  }
);

const HiddenInput = styled.input`
  display: none;
`;

const StyledTooltip = styled(Tooltip)`
  .MuiTooltip-tooltipPlacementTop {
    margin-bottom: 0;
    margin-top: 24px;
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.65);
  border-radius: 4px;
  .MuiCircularProgress-colorPrimary {
    color: var(--button-color);
    opacity: 0.65;
  }
`;

const CustomButton = styled(Button)`
  &:hover {
    background-color: ${COLORS.buttonActive};
    color: ${COLORS.buttonTextActive};
  }
`;
