import React, { Fragment, useState, FC, Dispatch, lazy, Suspense } from "react";
import { AppBar, Toolbar, IconButton, Badge, Button } from "@mui/material";
import { Menu } from "@mui/icons-material";
import styled from "@emotion/styled";

import { Link, useNavigate, useLocation } from "react-router-dom";

import { ActivityIcon, BackIcon, SearchIcon } from "icons";

import { useRefer } from "components/refer";
import { CLICK_MENU, CLICK_UPGRADE_BUTTON } from "utills/events";
import ReactGA from "react-ga4";

const ProfileDropdown = lazy(() => import("./ProfileDropdown"));
const Search = lazy(() => import("./Search"));
const SideMenu = lazy(() => import("./SideMenu"));
const HeaderLogo = lazy(() => import("components/HeaderLogo"));
const NineDotDropdown = lazy(() => import("../../NineDotDropdown"));
const NotificationCount = lazy(() => import("./NotificationCount"));
interface prompt {
    openPrompt: boolean
    setOpenPrompt: Dispatch<React.SetStateAction<boolean>>
}

const Header: FC<prompt> = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { handleOpenReferDrawer } = useRefer();
    const [open, setOpen] = useState<boolean>(false);



    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }
        setOpen(open);
    };

    const handleOpenSideMenu = () => setOpen(true);
    const handleCloseSideMenu = () => setOpen(false);

    const handleBack = () => {
        if (history.length > 1) {
            navigate(-1);
        } else {
            window.close();
        }
    }

    const isHomePage = pathname === "/home";

    console.log("History", history)
    return (
        <Fragment>
            <CustomAppBar elevation={0}>
                <CustomToolbar>
                    <Left>
                        {isHomePage ?
                            <MobileIconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    ReactGA.event(CLICK_MENU, {})
                                    handleOpenSideMenu();
                                }}
                                id="btnHomeBurger"
                            >
                                <Menu />
                            </MobileIconButton> : <MobileIconButton
                                edge="start"
                                color="inherit"
                                onClick={handleBack}
                                id="btnGoBack"
                            >
                                <BackIcon />
                            </MobileIconButton>}
                        <Suspense fallback={<Fragment />}>
                            <HeaderLogo component={Link} to="/home" />
                        </Suspense>
                    </Left>
                    <Center>
                        <Suspense fallback={<Fragment />}>
                            <Search />
                        </Suspense>
                    </Center>
                    <Right>
                        <Button
                            href="https://sedg.in/kcz5j13v"
                            variant="contained"
                            disableElevation
                            target="_blank"
                            rel="noopener"
                            sx={{ marginRight: 3 }}
                            onClick={() => {
                                ReactGA.event(CLICK_UPGRADE_BUTTON)
                            }}
                            
                        >Upgrade</Button>
                        <MobileSearchIconButton color="inherit"
                            onClick={() => navigate("/search")}
                            className="Search"
                        >
                            <SearchIcon />
                        </MobileSearchIconButton>
                        <IconButton
                            color="inherit"
                            onClick={() => navigate("/watchlist")}
                            className="Watchlist"
                            data-tut="reactour__watchlist"
                            
                        >
                            <Badge
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                badgeContent="watch"
                                color="error"
                                sx={{zIndex:1}}
                                
                            >
                                <ActivityIcon color="green" />
                            </Badge>
                        </IconButton>
                        <Suspense fallback={<Fragment />}>
                            <NotificationCount />
                        </Suspense>
                        <Suspense fallback={<Fragment />}>
                            <NineDotDropdown />
                        </Suspense>
                        <Suspense fallback={<Fragment />}>
                            <ProfileDropdown />
                        </Suspense>
                    </Right>
                </CustomToolbar>
            </CustomAppBar>
            <Toolbar />
            <Suspense fallback={<Fragment />}>
                <SideMenu
                    open={open}
                    onClose={toggleDrawer(false)}
                    handleCloseSideMenu={handleCloseSideMenu}
                />
            </Suspense>
        </Fragment>
    )
}

export default Header;

const Left = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const Center = styled.div`
    display: none;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: 960px) {
        display: flex;
    }
`;

const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const MobileIconButton = styled(IconButton)`
    @media (min-width : 960px) {
        display: none;
    }
`;

const MobileSearchIconButton = styled(IconButton)`
    @media (min-width : 960px) {
        display: none;
    }
`;

const CustomAppBar = styled(AppBar)`
    &&{
        .MuiTypography-h4 {
            display: none;
            align-items: center;
            font-size: 1rem;
            margin-left: 4px;
        }
        a {
            text-decoration: none;
        }

        @media (min-width: 360px){
            .MuiTypography-h4 {
                display: inline-flex;
            }
        }

        @media (min-width: 481px){
            .MuiTypography-h4 {
                font-size: 1.25rem;
            }
        }
        
        @media (min-width: 768px){
            .MuiTypography-h4 {
                font-size: 1.5rem;
                margin-left: 8px;
            }
        }

        @media only screen and (min-width : 960px){
            .MuiToolbar-root {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
`;

const CustomToolbar = styled(Toolbar)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 12px;
    padding-right: 12px;
    @media (min-width: 960px){
        grid-template-columns: 280px 1fr 280px;
    }
    @media (min-width: 1025px){
        grid-template-columns: 320px 1fr 320px;
    }
    @media (min-width: 1281px){
        grid-template-columns: 360px 1fr 360px;
    }
`;