import { CircularProgress, styled } from '@mui/material';

export const MenuLoader = () => {
    return (
        <Center>
            <CircularProgress />
        </Center>
    )
}

const Center = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiCircularProgress-root {
        height: 16px !important;
        width: 16px !important;
    }
`;
